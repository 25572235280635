import { Link } from 'react-router-dom'

import useTheme from '@mui/material/styles/useTheme'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { AppContainer } from '../../Components'

export default function NotFound() {
  const theme = useTheme()
  return (
    <AppContainer>
      <Box pt={10} pb={10}>
        <Stack spacing={8}>
          <Box>
            <Typography variant='h4' color='secondary' gutterBottom>
              Page Not Found
            </Typography>
            <Typography variant='body2'>
              Looks like we've misplaced this page. Let's get you back on track,
              below are links to all our brands.
            </Typography>
          </Box>

          <Stack spacing={2}>
            {['Phonak', 'Resound', 'Starkey', 'Oticon'].map((brand) => (
              <Link to={`/brand/${brand.toLowerCase()}`} key={brand}>
                <Typography
                  variant='body2'
                  color={theme.palette.info.main}
                  sx={{ textDecoration: 'underline' }}
                >
                  {brand}
                </Typography>
              </Link>
            ))}
          </Stack>
        </Stack>
      </Box>
    </AppContainer>
  )
}
