import useTheme from '@mui/material/styles/useTheme'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { AppContainer } from '../Components'

function Header({ children }) {
  return (
    <Typography
      variant='h5'
      color='secondary'
      sx={{ fontSize: '1rem' }}
      gutterBottom
    >
      {children}
    </Typography>
  )
}

function SubHeader({ children }) {
  return (
    <Typography
      variant='h6'
      color='secondary'
      sx={{ fontSize: '0.875rem' }}
      gutterBottom
    >
      {children}
    </Typography>
  )
}

function Italic({ children }) {
  return (
    <Typography
      variant='body2'
      sx={{ fontSize: '0.8rem', fontStyle: 'italic' }}
      gutterBottom
    >
      {children}
    </Typography>
  )
}

function Body({ children }) {
  return (
    <Typography variant='body2' sx={{ fontSize: '0.8rem' }} gutterBottom>
      {children}
    </Typography>
  )
}

export default function Terms() {
  const theme = useTheme()

  return (
    <AppContainer>
      <Stack
        spacing={2}
        sx={{ padding: `${theme.spacing(8)} ${theme.spacing(4)}` }}
      >
        <Body>LAST MODIFIED: OCTOBER 3, 2021</Body>

        <Header>Terms of Use</Header>
        <Body>
          These Terms of Use ("Terms") are a legal contract between you ("you")
          and Greenlight Marketing LLC d/b/a The Hearing Agency ("Greenlight",
          "us", "our" or "we") and govern your use of all the text, data,
          information, video, graphics, photographs, conversations with
          Greenlight employees and agents, and other materials (collectively
          referred to as "Materials") that we may make available to you through
          any area of the Greenlight hosted service (the "Platform"), and any
          services we may provide through the Platform ("Services").
        </Body>
        <Body>
          READ THESE TERMS CAREFULLY BEFORE ACCESSING AND USING THE PLATFORM.
          USING THE PLATFORM OR ANY SERVICES INDICATES THAT YOU HAVE BOTH READ
          AND ACCEPTED THESE TERMS. YOU CANNOT USE THE PLATFORM OR OUR SERVICES
          IF YOU DO NOT ACCEPT THESE TERMS.
        </Body>
        <Body>
          NOTE: THESE TERMS CONTAIN A DISPUTE RESOLUTION AND ARBITRATION
          PROVISION, INCLUDING CLASS ACTION WAIVER THAT AFFECTS YOUR RIGHTS
          UNDER THESE TERMS AND WITH RESPECT TO DISPUTES YOU MAY HAVE WITH
          GREENLIGHT. YOU MAY OPT OUT OF THE BINDING INDIVIDUAL ARBITRATION AND
          CLASS ACTION WAIVER AS PROVIDED BELOW.
        </Body>

        <SubHeader>CHANGES.</SubHeader>
        <Body>
          We may alter the Materials and Services we offer you and/or may choose
          to modify, suspend or discontinue any part or all of the Platform or
          Services at any time and without notifying you. We may also change,
          update, add or remove provisions of these Terms from time to time. We
          will use commercially reasonable efforts to inform you of any
          modifications to these Terms by posting them on the Platform.
        </Body>
        <Body>
          If you object to any such modifications, your sole recourse shall be
          to cease using the Platform, Materials and Services. Continued use of
          the Platform, Materials and/or Services following notice of any such
          modifications indicates you acknowledge and agree to be bound by the
          modifications. Also, please know that these Terms may be superseded by
          expressly-designated legal notices or terms located on particular
          pages of the Platform or on the sites of our partners. Such
          expressly-designated legal notices or terms are incorporated into
          these Terms and supersede the provision(s) of these Terms that are
          designated as being superseded.
        </Body>
        <SubHeader>GENERAL USE.</SubHeader>
        <Body>
          By using the Platform, you agree that you are at least 18 years of
          age, or if you are under 18 years of age (a "Minor"), that you are
          using the Platform with the consent of your parent or legal guardian
          and you have received your parent's or legal guardian's permission to
          use the Platform and agree to these Terms. If you are a parent or
          legal guardian of a Minor, you agree to bind the Minor to these Terms
          and to fully indemnify and hold us harmless if the Minor breaches any
          of these Terms.
        </Body>
        <Body>
          In these Terms we are granting you a limited, personal, non-exclusive
          and non-transferable license to use and to display the Materials; your
          right to use the Materials is conditioned on your compliance with
          these Terms. You have no other rights in the Platform or any Materials
          and you may not modify, edit, copy, reproduce, create derivative works
          of, reverse engineer, alter, enhance or in any way exploit the
          Platform or any of the Materials in any manner. If you make copies of
          any of the Materials then we ask that you be sure to keep on the
          copies all of our copyright and other proprietary notices as they
          appear on the Platform.
        </Body>
        <Body>
          Unfortunately, if you breach any of these Terms, the above license
          will terminate automatically and you must immediately destroy any
          downloaded or printed Materials (and any copies thereof).
        </Body>
        <SubHeader>USING THE PLATFORM.</SubHeader>
        <Body>
          In order to access the Platform and utilize the Service, you must
          provide the information requested when you first access the Platform.
          You are responsible for complying with these Terms when you access and
          use the Platform and for obtaining and maintaining all equipment and
          services needed for you to access and use the Platform and paying
          related charges.
        </Body>
        <SubHeader>ELECTRONIC COMMUNICATIONS.</SubHeader>
        <Body>
          By using the Platform (or any part thereof), you consent to being
          called by us and to receiving electronic communications from us
          (including, if you have opted in, via phone or text message) and from
          other users of the Services. These electronic communications may
          include transactional information and other information concerning or
          related to the Platform. These electronic communications are part of
          your relationship with us. You agree that any notices, agreements,
          disclosures or other communications that we send you electronically
          will satisfy any legal communication requirements, including that such
          communications be in writing. Standard carrier data charges may apply
          to your use of text messaging and you are solely responsible for such
          charges. If you have opted in to receiving telemarketing or
          advertising communications via phone or text message, you understand
          that calls and/or text messages made or sent for advertising purposes
          may be generated using prerecorded messages or artificial voice, and
          calls and messages may be delivered using an auto telephone dialing
          system or an automatic texting system. You further understand that
          your consent is not required as a condition of doing business with us.
        </Body>
        <SubHeader>PRIVACY POLICY.</SubHeader>
        <Body>
          We respect the information that you provide to us and want to be sure
          you fully understand exactly how we use that information. Please
          review our privacy policy ("Privacy Policy"), which explains how we
          collect and use personal information.
        </Body>
        <SubHeader>LINKS TO THIRD-PARTY SITES.</SubHeader>
        <Body>
          We think links are convenient, and we sometimes provide links on the
          Platform to third-party websites. If you use these links, you will
          leave our Platform. We are not obligated to review any third-party
          websites that you link to from the Platform, we do not control any of
          the third-party websites, and we are not responsible for any of the
          third-party websites (or the products, services, or content available
          through any of them). Therefore, unless specifically stated on the
          Platform, we do not endorse or make any representations about such
          third-party websites, any information, software, products, services,
          or materials found there or any results that may be obtained from
          using them. If you decide to access any of the third-party websites
          linked to from the Platform, you do so entirely at your own risk and
          you must follow the privacy policies and terms and conditions for
          those third-party websites.
        </Body>
        <SubHeader>UNAUTHORIZED ACTIVITIES.</SubHeader>
        <Body>
          To be clear, we authorize your use of the Platform only for
          individual, consumer purposes ("Permitted Purposes"). Any other use of
          the Platform beyond the Permitted Purposes is prohibited and,
          therefore, constitutes unauthorized use of the Platform. This is
          because as between you and Greenlight, all rights in the Platform
          remain our property.
        </Body>
        <Body>
          Unauthorized use of the Platform may result in violation of various
          United States and international copyright laws. Because we prefer
          keeping this relationship drama-free, we want to give you examples of
          things to avoid. So, unless you have written permission from us
          stating otherwise, you are not authorized to use the Platform in any
          of the following ways (these are examples only and the list below is
          not a complete list of everything that you are not permitted to do):
        </Body>
        <ul>
          <li>
            <Body>
              For any public or commercial purpose which includes use of the
              Materials on another site or through a networked computer
              environment;
            </Body>
          </li>
          <li>
            <Body>
              In a manner that modifies, publicly displays, publicly performs,
              reproduces or distributes any of the Platform;
            </Body>
          </li>
          <li>
            <Body>
              In a manner that violates any local, state, national, foreign, or
              international statute, regulation, rule, order, treaty, or other
              law;
            </Body>
          </li>
          <li>
            <Body>To stalk, harass, or harm another individual;</Body>
          </li>
          <li>
            <Body>
              To impersonate any person or entity or otherwise misrepresent your
              affiliation with a person or entity;
            </Body>
          </li>
          <li>
            <Body>
              To interfere with or disrupt the Platform or servers or networks
              connected to the Platform;
            </Body>
          </li>
          <li>
            <Body>
              To use any data mining, robots, or similar data gathering or
              extraction methods in connection with the Platform; or
            </Body>
          </li>
          <li>
            <Body>
              To attempt to gain unauthorized access to any portion of the
              Platform or any other accounts, computer systems, or networks
              connected to the Platform, whether through hacking, password
              mining, or any other means.
            </Body>
          </li>
        </ul>
        <Body>
          You agree to hire attorneys to defend us if you violate these Terms
          and that violation results in a problem for us. You also agree to pay
          any damages that we may end up having to pay as a result of your
          violation. You alone are responsible for any violation of these Terms
          by you. We reserve the right to assume the exclusive defense and
          control of any matter otherwise subject to indemnification by you and,
          in such case, you agree to cooperate with our defense of such claim.
        </Body>
        <SubHeader>PROPRIETARY RIGHTS.</SubHeader>
        <Body>
          The trademarks, service marks, and logos of Greenlight and our
          affiliates ("Our Trademarks") used and displayed on various parts of
          the Platform are registered and unregistered trademarks or service
          marks of Greenlight. Other company, product, and service names located
          on the Platform may be trademarks or service marks owned by others
          (the "Third-Party Trademarks", and, collectively with Our Trademarks,
          the "Trademarks"). Nothing in these Terms should be construed as
          granting, by implication, estoppel, or otherwise, any license or right
          to use the Trademarks, without our prior written permission specific
          for each such use. Use of the Trademarks as part of a link to or from
          any site is prohibited unless establishment of such a link is approved
          in advance by us in writing. All goodwill generated from the use of
          Our Trademarks inures to our benefit.
        </Body>
        <Body>
          Unless otherwise specified in these Terms, all Materials, including
          the arrangement of them on the Platform are our sole property, or the
          property of our suppliers and licensors. All rights not expressly
          granted herein are reserved. Except as otherwise required or limited
          by applicable law, any reproduction, distribution, modification,
          retransmission, or publication of any copyrighted material is strictly
          prohibited without the express written consent of the copyright owner
          or license.
        </Body>
        <SubHeader>THIRD PARTY RELATIONSHIPS AND COMPENSATION.</SubHeader>
        <Body>
          The Platform is a free online resource that offers valuable content
          and features to consumers. To keep such a resource 100% free for
          consumers, Greenlight receives marketing and sales compensation from
          some of the companies listed on this page. Such compensation may
          impact which companies are listed on the Site, the order they appear,
          and the information provided. The Platform does not include all
          providers or companies that sell hearing aids.
        </Body>
        <SubHeader>NO ENDORSEMENTS.</SubHeader>
        <Body>
          The information on the Platform are advertisements and participating
          companies pay a fee to Greenlight when they acquire new clients
          through the Services. We do not select, approve, or otherwise endorse
          any particular hearing aid, hearing aid manufacturer, or hearing aid
          provider. Nor does Greenlight make any representation about the
          quality of the services, the qualifications of any particular hearing
          aid provider, or the suitability of the services they provide for your
          particular needs.
        </Body>
        <SubHeader>YOUR RELATIONSHIP WITH US; DISPUTES AMONG USERS.</SubHeader>
        <Body>
          We are not a party to any purchase or contract. You hereby acknowledge
          and agree that we are NOT a party to any oral or written agreement for
          product or services or any other contract entered into between users
          of the Platform and third parties in connection with any Services we
          offer, including but not limited to contracts entered into between you
          and any of our third-party marketing partners.
        </Body>
        <Body>
          No agency or partnership. No agency, partnership, joint venture, or
          employment is created as a result of these Terms or your use of any
          part of the Platform. You do not have any authority whatsoever to bind
          us in any respect. Neither we nor you may direct or control the
          day-to-day activities of the other or create or assume any obligation
          on behalf of the other.
        </Body>
        <Body>
          Disputes between users and between users and third parties. Your
          interactions with individuals and/or organizations found on or through
          the Platform, the provision of products or performance of any services
          by such parties, and any terms, conditions, warranties or
          representations associated with such transactions or dealings, are
          solely between you and such individual or organization. You should
          take reasonable precautions and make whatever investigation or
          inquiries you deem necessary or appropriate before proceeding with any
          online or offline transaction with any third party, including without
          limitation, any individuals or entities with whom you may interact as
          a result of your use of the Platform.
        </Body>
        <Body>
          You understand that deciding whether to use information found on the
          Platform and/or the products or services of any individual or
          organization, is your personal decision for which you alone are
          responsible. You understand that we do not and cannot make
          representations as to the suitability of any individual or
          organization you may decide to interact with on or through the
          Platform and/or the accuracy or suitability of any advice,
          information, or recommendations made by any such individual or
          organization.
        </Body>
        <Body>
          IN THE EVENT THAT A DISPUTE ARISES BETWEEN YOU AND ANY THIRD PARTY,
          YOU ACKNOWLEDGE AND AGREE THAT WE ARE UNDER NO OBLIGATION TO BECOME
          INVOLVED. FURTHER, YOU HEREBY RELEASE US, OUR OFFICERS, MANAGERS,
          MEMBERS, DIRECTORS, EMPLOYEES, ATTORNEYS, AGENTS, AND SUCCESSORS IN
          RIGHTS FROM ANY CLAIMS, DEMANDS, AND DAMAGES (ACTUAL AND
          CONSEQUENTIAL) OF EVERY KIND OR NATURE, KNOWN OR UNKNOWN, SUSPECTED OR
          UNSUSPECTED, FORESEEABLE OR UNFORESEEABLE, DISCLOSED OR UNDISCLOSED,
          ARISING OUT OF OR IN ANY WAY RELATED TO SUCH DISPUTES. IF YOU ARE A
          CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE §1542, WHICH
          SAYS: "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR
          OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT
          THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE
          MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY."
        </Body>
        <SubHeader>DISCLAIMER OF WARRANTIES.</SubHeader>
        <Body>
          THE PLATFORM, MATERIALS AND SERVICES ARE PROVIDED "AS IS" AND "WITH
          ALL FAULTS", AND THE ENTIRE RISK AS TO THEIR USE IS WITH YOU. WE
          EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND (EXPRESS, IMPLIED OR
          STATUTORY) WITH RESPECT TO THE PLATFORM, MATERIALS AND SERVICES, WHICH
          INCLUDES BUT IS NOT LIMITED TO, ANY IMPLIED OR STATUTORY WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE, TITLE, AND
          NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS.
        </Body>
        <Body>
          WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE MAKE NO WARRANTY
          THAT THE PLATFORM, MATERIALS AND SERVICES WILL MEET YOUR REQUIREMENTS,
          THAT YOUR USE OF THE PLATFORM WILL BE UNINTERRUPTED, TIMELY, SECURE,
          OR ERROR FREE OR THAT DEFECTS IN THE PLATFORM WILL BE CORRECTED. WE
          MAKE NO WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE
          OF THE PLATFORM, MATERIALS AND SERVICES, OR AS TO THE ACCURACY OR
          RELIABILITY OF ANY INFORMATION OR SERVICES OBTAINED THROUGH THE USE OF
          THE PLATFORM, AND ARE NOT RESPONSIBLE FOR THE PRODUCTS, SERVICES,
          ACTIONS, OR FAILURE TO ACT OF ANY THIRD PARTY. NO ADVICE OR
          INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU THROUGH THE
          PLATFORM OR FROM US OR OUR SUBSIDIARIES/OTHER AFFILIATED COMPANIES
          SHALL CREATE ANY WARRANTY. WE DISCLAIM ALL EQUITABLE INDEMNITIES.
        </Body>
        <SubHeader>LIMITATION OF LIABILITY.</SubHeader>
        <Body>
          YOU ARE USING THE PLATFORM, MATERIALS, PRODUCTS AND SERVICES AT YOUR
          SOLE RISK. WE SHALL NOT BE LIABLE TO YOU FOR ANY DAMAGES RESULTING
          FROM YOUR DISPLAYING, COPYING, OR DOWNLOADING ANY MATERIALS TO OR FROM
          THE PLATFORM OR YOUR USE OF OUR SERVICES. IN NO EVENT SHALL WE BE
          LIABLE TO YOU FOR ANY INDIRECT, EXTRAORDINARY, EXEMPLARY, PUNITIVE,
          SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, OR DAMAGES RESULTING
          FROM LOSS OF DATA, REVENUE, PROFITS, COST OF SUBSTITUTE GOODS AND
          SERVICES, USE, OR OTHER ECONOMIC ADVANTAGE, HOWEVER ARISING, EVEN IF
          WE KNOW THERE IS A POSSIBILITY OF SUCH DAMAGE.
        </Body>
        <Body>
          YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND
          INTERACTIONS WITH OTHER USERS OF THE PLATFORM, AND WITH OTHER PERSONS
          OR ENTITIES WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR
          USE THEREOF. YOU UNDERSTAND THAT WE DO NOT MAKE ANY ATTEMPT TO VERIFY
          THE STATEMENTS OF USERS OF THE PLATFORM. WE MAKE NO REPRESENTATIONS OR
          WARRANTIES AS TO THE CONDUCT OF USERS OF THE PLATFORM OR THEIR
          COMPATIBILITY WITH ANY CURRENT OR FUTURE USERS OF THE PLATFORM. YOU
          AGREE TO TAKE REASONABLE PRECAUTIONS IN ALL COMMUNICATIONS AND
          INTERACTIONS WITH ANY PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS
          A RESULT OF YOUR USE OF THE PLATFORM, PARTICULARLY IF YOU MEET OFFLINE
          OR IN PERSON, REGARDLESS OF WHETHER WE FACILITATE SUCH MEETINGS. WE
          EXPLICITLY DISCLAIM ALL LIABILITY FOR ANY ACT OR OMISSION OF ANY USER
          OF THE PLATFORM AND ALL OTHER THIRD PARTIES.
        </Body>
        <SubHeader>FEEDBACK.</SubHeader>
        <Body>
          Any submissions by you to us regarding the Platform (e.g., comments,
          questions, suggestions, materials – collectively, "Feedback") through
          any communication whatsoever (e.g., call, fax, email) will be treated
          as both non-confidential and non-proprietary. You hereby assign all
          right, title, and interest in, and we are free to use, without any
          attribution or compensation to you, any ideas, know-how, concepts,
          techniques, or other intellectual property and proprietary rights
          contained in the Feedback, whether or not patentable, for any purpose
          whatsoever, including but not limited to, developing, manufacturing,
          having manufactured, licensing, marketing, and selling, directly or
          indirectly, products and services using such Feedback. Where the
          foregoing assignment is prohibited by law, you hereby grant us an
          exclusive, transferable, worldwide, royalty-free, fully paid up
          license (including the right to sublicense) to use and exploit all
          Feedback as we may determine in our sole discretion. You understand
          and agree, however, that we are not obligated to use, display,
          reproduce, or distribute any such ideas, know-how, concepts, or
          techniques contained in the Feedback, and you have no right to compel
          such use, display, reproduction, or distribution.
        </Body>
        <SubHeader>
          DISPUTE RESOLUTION AND ARBITRATION; CLASS ACTION WAIVER.
        </SubHeader>
        <Body>
          Please read the following Dispute Resolution and Arbitration; Class
          Action Waiver provision (this "Provision") carefully. It affects your
          rights.
        </Body>
        <Body>
          Please read this Provision carefully. It provides that all Disputes
          (as defined below) between you and Greenlight shall be resolved by
          binding arbitration. Arbitration is a form of private dispute
          resolution and replaces the right to go to court. In the absence of
          this arbitration agreement, you may otherwise have a right or
          opportunity to bring claims in a court, before a judge or jury, and/or
          to participate in or be represented in a case filed in court by others
          (including, but not limited to, class actions). Except as otherwise
          provided, entering into these Terms constitutes a waiver of your right
          to litigate claims and all opportunity to be heard by a judge or jury.
          There is no judge or jury in arbitration, and court review of an
          arbitration award is limited. The arbitrator must follow these Terms
          and can award the same damages and relief as a court (including
          attorney's fees).
        </Body>
        <Body>
          For purposes of this Provision, "Greenlight" means Greenlight
          Marketing LLC and its parents, subsidiaries, and affiliate companies,
          and each of their respective officers, directors, employees, and
          agents. The term "Dispute" means any dispute, claim, or controversy
          between you and Greenlight regarding, arising out of or relating to
          any aspect of your relationship with Greenlight, whether based in
          contract, statute, regulation, ordinance, tort (including, but not
          limited to, fraud, misrepresentation, fraudulent inducement, or
          negligence), or any other legal or equitable cause of action or claim
          for relief, and includes the validity, enforceability or scope of this
          Provision (with the exception of the enforceability of the Class
          Action Waiver clause below). "Dispute" is to be given the broadest
          possible meaning that will be enforced, and shall include any claims
          against other parties relating to services or products provided or
          billed to you (such as Greenlight's licensors, suppliers, dealers or
          third-party vendors) whenever you also assert claims against us in the
          same proceeding.
        </Body>
        <Body>
          YOU AND GREENLIGHT EACH AGREE THAT, EXCEPT AS PROVIDED BELOW, ANY AND
          ALL DISPUTES, AS DEFINED ABOVE, WHETHER PRESENTLY IN EXISTENCE OR
          BASED ON ACTS OR OMISSIONS IN THE PAST OR IN THE FUTURE, WILL BE
          RESOLVED EXCLUSIVELY AND FINALLY BY BINDING ARBITRATION RATHER THAN IN
          COURT IN ACCORDANCE WITH THIS PROVISION.
        </Body>
        <Italic>Pre-Arbitration Claim Resolution.</Italic>
        <Body>
          For all Disputes, whether pursued in court or arbitration, you must
          first give Greenlight an opportunity to resolve the Dispute. You must
          commence this process by mailing us written notification at:
          Greenlight Marketing LLC, 534 Bloomfield St., Suite 100, Hoboken, NJ
          07030. That written notification must include (1) your name, (2) your
          address, (3) a written description of the Dispute, and (4) a
          description of the specific relief you seek. If Greenlight does not
          resolve the Dispute within 45 days after it receives your written
          notification, you may pursue your Dispute in arbitration. You may
          pursue your Dispute in a court only under the circumstances described
          below.
        </Body>
        <Italic>Exclusions from Arbitration/Right to Opt Out.</Italic>
        <Body>
          Notwithstanding the above, you or Greenlight may choose to pursue a
          Dispute in court and not by arbitration if (a) the Dispute qualifies,
          it may be initiated in small claims court; or (b) YOU OPT-OUT OF THESE
          ARBITRATION PROCEDURES WITHIN 30 DAYS FROM THE DATE THAT YOU FIRST
          CONSENT TO THESE TERMS (the "Opt-Out Deadline"). You may opt out of
          this Provision by mailing us written notification at: Greenlight
          Marketing LLC, 534 Bloomfield St., Suite 100, Hoboken, NJ 07030. Your
          written notification must include (1) your name, (2) your address, and
          (3) a clear statement that you do not wish to resolve disputes with
          Greenlight through arbitration. Your decision to opt-out of this
          Provision will have no adverse effect on your relationship with
          Greenlight. Any opt-out request received after the Opt-Out Deadline
          will not be valid and you must pursue your Dispute in arbitration or
          small claims court.
        </Body>
        <Italic>Arbitration Procedures.</Italic>
        <Body>
          If this Provision applies and the Dispute is not resolved as provided
          above (Pre-Arbitration Claim Resolution) either you or Greenlight may
          initiate arbitration proceedings. The American Arbitration Association
          ("AAA"), www.adr.org, or JAMS, www.jamsadr.com, will arbitrate all
          Disputes, and the arbitration will be conducted before a single
          arbitrator. The arbitration shall be commenced as an individual
          arbitration only, and shall in no event be commenced as a class
          arbitration or a consolidated or representative action or arbitration.
          All issues shall be for the arbitrator to decide, including the scope
          of this Provision.
        </Body>
        <Body>
          For arbitration before AAA, for Disputes of less than $75,000, the
          AAA's Supplementary Procedures for Consumer-Related Disputes will
          apply; for Disputes involving $75,000 or more, the AAA's Commercial
          Arbitration Rules will apply. In either instance, the AAA's Optional
          Rules For Emergency Measures Of Protection shall apply. The AAA rules
          are available at www.adr.org or by calling 1-800-778-7879. For
          arbitration before JAMS, the JAMS Comprehensive Arbitration Rules &
          Procedures and the JAMS Recommended Arbitration Discovery Protocols
          For Domestic, Commercial Cases will apply. The JAMS rules are
          available at www.jamsadr.com or by calling 1-800-352-5267. This
          Provision governs in the event it conflicts with the applicable
          arbitration rules. Under no circumstances will class action or
          representative action procedures or rules apply to the arbitration.
        </Body>
        <Body>
          Because the Platform and these Terms concern interstate commerce, the
          Federal Arbitration Act ("FAA") governs the arbitrability of all
          Disputes. However, the arbitrator will apply applicable substantive
          law consistent with the FAA and the applicable statute of limitations
          or condition precedent to suit.
        </Body>
        <Body>
          Arbitration Award – The arbitrator may award on an individual basis
          any relief that would be available pursuant to applicable law, and
          will not have the power to award relief to, against or for the benefit
          of any person who is not a party to the proceeding. The arbitrator
          will make any award in writing but need not provide a statement of
          reasons unless requested by a party or if required by applicable law.
          Such award will be final and binding on the parties, except for any
          right of appeal provided by the FAA or other applicable law, and may
          be entered in any court having jurisdiction over the parties for
          purposes of enforcement.
        </Body>
        <Body>
          Location of Arbitration – You or Greenlight may initiate arbitration
          in Hudson County, New Jersey or the federal judicial district that
          includes your billing address. However, if Greenlight initiates the
          arbitration in Hudson County, you shall have the right to have the
          arbitration transferred to the AAA or JAMS office within the judicial
          district that includes your billing address.
        </Body>
        <Body>
          JAMS hearing fees and any arbitrator's hearing fees, costs and
          expenses upon your written request to the arbitrator given at or
          before the first evidentiary hearing in the arbitration. You are
          responsible for all additional fees and costs that you incur in the
          arbitration, including, but not limited to, attorneys or expert
          witnesses. Fees and costs may be awarded as provided pursuant to
          applicable law. In addition to any rights to recover fees and costs
          under applicable law, if you provide notice and negotiate in good
          faith with Greenlight as provided in the section above titled
          "Pre-Arbitration Claim Resolution" and the arbitrator concludes that
          you are the prevailing party in the arbitration, you will be entitled
          to recover reasonable attorney's fees and costs as determined by the
          arbitrator.
        </Body>
        <Italic>Class Action Waiver.</Italic>
        <Body>
          Except as otherwise provided in this Provision, the arbitrator may not
          consolidate more than one person's claims, and may not otherwise
          preside over any form of a class or representative proceeding or
          claims (such as a class action, consolidated action, representative
          action, or private attorney general action) unless both you and
          Greenlight specifically agree to do so in writing following initiation
          of the arbitration. If you choose to pursue your Dispute in court by
          opting out of the Provision, as specified above, this Class Action
          Waiver will not apply to you. Neither you, nor any other user of the
          Platform can be a class representative, class member, or otherwise
          participate in a class, consolidated, or representative proceeding
          without having complied with the opt-out requirements above.
        </Body>
        <Italic>No Judge or Jury in Arbitration.</Italic>
        <Body>
          Arbitration does not involve a judge or jury. You understand and agree
          that by entering into these Terms you and Greenlight are each giving
          up the right to a jury trial or a trial before a judge in a public
          court. In the absence of this Provision, you and Greenlight might
          otherwise have had a right or opportunity to bring Disputes in a
          court, before a judge or jury, and/or to participate or be represented
          in a case filed in court by others (including class actions). Except
          as otherwise provided below, those rights are waived. Other rights
          that you would have if you went to court, such as the right to appeal
          and to certain types of discovery, may be more limited or may also be
          waived.
        </Body>
        <Italic>Severability.</Italic>
        <Body>
          If any clause within this Provision (other than the Class Action
          Waiver clause above) is found to be illegal or unenforceable, that
          clause will be severed from this Provision, and the remainder of this
          Provision will be given full force and effect. If the Class Action
          Waiver clause is found to be illegal or unenforceable, this entire
          Provision will be unenforceable, and the Dispute will be decided by a
          court.
        </Body>
        <Italic>Continuation.</Italic>
        <Body>
          This Provision shall survive the termination of your service with
          Greenlight or its affiliates. Notwithstanding any provision in these
          Terms to the contrary, we agree that if Greenlight makes any change to
          this Provision (other than a change to the Notice Address), you may
          reject any such change and require Greenlight to adhere to the present
          language in this Provision if a dispute between us arises.
        </Body>
        <SubHeader>GENERAL.</SubHeader>
        <Body>
          We prefer to advise you if we feel you are not complying with these
          Terms and to recommend any necessary corrective action. However,
          certain violations of these Terms, as determined by us, may result in
          immediate termination of your access to the Platform and Services
          without prior notice to you. Except for Disputes subject to
          arbitration as described above, any disputes relating to these Terms
          or the Platform will be heard in the courts located in Hudson County
          in the State of New Jersey. If any of these Terms is found to be
          inconsistent with applicable law, then such term shall be interpreted
          to reflect the intentions of the parties, and no other terms will be
          modified. Our failure to enforce any of these Terms is not a waiver of
          such term. These Terms are the entire agreement between you and
          Greenlight and supersede all prior or contemporaneous negotiations,
          discussions or agreements between you and Greenlight about the
          Platform. The proprietary rights, disclaimer of warranties,
          representations made by you, indemnities, limitations of liability and
          general provisions shall survive any termination of these Terms.
        </Body>
        <SubHeader>CONTACT US.</SubHeader>
        <Body>
          If you have any questions about these Terms or otherwise need to
          contact us for any reason, you can reach us at
          support@hellogreenlight.com.
        </Body>
      </Stack>
    </AppContainer>
  )
}
