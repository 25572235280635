import { useEffect, useState, useContext } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import Stack from '@mui/material/Stack'

import './App.css'

import { Loading, Footer, Header } from './Components'

import { setTrackingIds } from './lib/functions'
// import { useWindowSize, useDocumentTitle } from './lib/hooks'
import { useWindowSize } from './lib/hooks'
import { ContentContext } from './lib/context'

import { Pageview, Routes, ScrollToTop } from './utilities'

function App() {
  const windowSize = useWindowSize()
  const { loadingContent, content } = useContext(ContentContext)

  const [qaSession, setQASession] = useState(false)

  // const hostname = window.location.hostname
  // const ha = hostname === 'hearingagency.com'

  // useDocumentTitle(
  //   ha
  //     ? 'The Hearing Agency - Hearing Aids & Audiologist Care Delivered To Your Door'
  //     : 'Yes Hearing - Hearing Aids & Audiologist Care Delivered To Your Door'
  // )

  useEffect(() => {
    setTrackingIds()
  }, [])

  useEffect(() => {
    const queryParams = content && content.queryParams

    if (queryParams && 'qa' in queryParams) {
      setQASession(queryParams.qa)
    }
  }, [content])

  if (loadingContent) {
    return <Loading size={60} justify='center' pt={25} />
  }

  return (
    <Router>
      <ScrollToTop />
      {qaSession ? null : <Pageview />}
      <Stack
        justifyContent='space-between'
        sx={{ minHeight: windowSize && windowSize.height }}
      >
        <Header />
        <Routes />
        <Footer />
      </Stack>
    </Router>
  )
}

export default App
