import { forwardRef, useState, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { IMaskInput } from 'react-imask'
import { useFormik } from 'formik'
import TagManager from 'react-gtm-module'
import validationSchema from '../../Brand/Form/validationSchema'

import useTheme from '@mui/material/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormHelperText from '@mui/material/FormHelperText'

import { getFirstPosition } from '../../../lib/functions'

import { firestore } from '../../../lib/firebase'

import { ContentContext } from '../../../lib/context'

import { addDoc, collection, serverTimestamp } from 'firebase/firestore'

import { Loading } from '../../../Components'

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props

  return (
    <IMaskInput
      {...other}
      mask='(#00) 000-0000'
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.id, value } })}
      overwrite
    />
  )
})

export default function Contact() {
  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'))
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const { pathname } = useLocation()
  const { content } = useContext(ContentContext)

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        const docRef = await addDoc(collection(firestore, 'leads.raw'), {
          ...values,
          type: 1,
          browserId: localStorage.getItem('browserId'),
          sessionId: sessionStorage.getItem('sessionId'),
          tenantId: 'wRzt80KPc06nd2lX9upo',
          sourceId: content.sourceData.sourceId,
          originalLocation: sessionStorage.getItem('originalLocation'),
          created: serverTimestamp()
        })
        if (docRef.id) {
          setTimeout(() => {
            const pageTitle = getFirstPosition(pathname)

            const tagManagerArgs = {
              dataLayer: {
                event: 'conversion',
                pagePath: pathname,
                pageTitle
              }
            }

            TagManager.dataLayer(tagManagerArgs)

            setSuccess(true)
          }, 500)
        }
      } catch (error) {
        setTimeout(() => {
          setLoading(false)
          setTimeout(() => {
            alert('Please wait a few minutes and try again.')
          }, 50)
        }, 500)
      }
    }
  })

  if (success) {
    return (
      <Stack
        id='form'
        spacing={theme.spacing(2)}
        justifyContent='center'
        align='center'
        sx={{ marginTop: theme.spacing(10), paddingBottom: theme.spacing(20) }}
      >
        <Typography variant='h4' color='secondary'>
          Thank You!
        </Typography>
        <Typography variant='body2'>{`Our licensed audiologist will call you at ${formik.values.phone} shortly. We're looking forward to talking with you, so please be ready to answer the phone.`}</Typography>
      </Stack>
    )
  }

  return (
    <Box
      sx={{
        padding: theme.spacing(4),
        borderRadius: 4,
        backgroundColor: content.ha
          ? `rgba(228, 143, 52, 0.25)`
          : `rgba(164,189,247, 0.25)`,
        marginTop: isLarge ? theme.spacing(5) : theme.spacing(0)
      }}
    >
      <form id='form' onSubmit={formik.handleSubmit}>
        <Stack
          spacing={4}
          sx={{
            width: '100%',
            paddingTop: theme.spacing(3)
          }}
        >
          {[
            { label: 'Name', id: 'name', placeholder: 'John Doe' },
            {
              label: 'Phone Number',
              id: 'phone',
              placeholder: '(212) 555-5555'
            },
            {
              label: 'Email Address',
              id: 'email',
              placeholder: 'john.doe@gmail.com'
            }
          ].map((item) => (
            <FormControl key={item.id}>
              <InputLabel
                htmlFor={item.id}
                variant='standard'
                error={
                  formik.touched[item.id] && Boolean(formik.errors[item.id])
                }
                sx={{
                  top: '-35%',
                  fontSize: '1.5rem',
                  color: theme.palette.secondary.main
                }}
                shrink
              >
                {item.label}
              </InputLabel>
              <OutlinedInput
                id={item.id}
                placeholder={item.placeholder}
                label={item.label}
                value={formik.values[item.id]}
                onChange={formik.handleChange}
                error={
                  formik.touched[item.id] && Boolean(formik.errors[item.id])
                }
                inputComponent={item.id === 'phone' ? TextMaskCustom : null}
                notched={false}
                fullWidth
                sx={{ backgroundColor: theme.palette.common.white }}
              />
              <FormHelperText
                error={
                  formik.touched[item.id] && Boolean(formik.errors[item.id])
                }
                sx={{ minHeight: '1.25rem' }}
              >
                {formik.touched[item.id] && formik.errors[item.id]}
              </FormHelperText>
            </FormControl>
          ))}
          <Typography
            sx={{ fontSize: '0.85rem', marginTop: '22px !important' }}
          >
            {content.ha
              ? `By clicking "Submit my information" below, you consent to Yes
            Hearing and/or Greenlight Marketing using automated technology to
            call or text you regarding marketing offers. This consent is not
            required to make a purchase.`
              : `By clicking "Submit my information" below, you consent to The
              Hearing Agency and/or Yes Hearing using automated technology to call
              or text you regarding marketing offers. This consent is not required
              to make a purchase.`}
          </Typography>
          {loading ? (
            <Loading pl={14.5} size={54} />
          ) : (
            <Button
              variant='contained'
              color={`callButton`}
              disableElevation
              component='button'
              type='submit'
              sx={{
                height: '3.375rem',
                minWidth: '17.375rem',
                width: '100%',
                border: ``,
                borderRadius: 2,
                marginTop: `${theme.spacing(4)}`,
                color: `#fff`,
                boxShadow: 8,
                '&:hover': {
                  color: theme.palette.common.white,
                  borderColor: theme.palette.primary.main
                }
              }}
            >
              <Typography
                variant='body1'
                sx={{
                  fontWeight: theme.typography.fontWeightMedium,
                  fontSize: isMedium ? '1.2rem' : '1.5rem'
                }}
              >
                Submit my information
              </Typography>
            </Button>
          )}
        </Stack>
      </form>
    </Box>
  )
}
