import React from 'react'
const TrustBox = () => {
  const ref = React.useRef(null)
  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])
  return (
    <div
      ref={ref}
      className='trustpilot-widget'
      data-locale='en-US'
      data-template-id='5419b6ffb0d04a076446a9af'
      data-businessunit-id='5fc67a080737a300019b2dd4'
      data-style-height='20px'
      data-style-width='100%'
      data-theme='light'
      data-text-color='#0B3F6F'
    >
      <a href='https://www.trustpilot.com/review/example.com'> Trustpilot</a>
    </div>
  )
}
export default TrustBox
