import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useTheme from '@mui/material/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

import { AppContainer, Loading } from '../../Components'

import Form from './Form'
import NotFound from './NotFound'

const validatePath = (key) => {
  switch (key) {
    case 'phonak':
    case 'unitron':
    case 'resound':
    case 'signia':
    case 'starkey':
    case 'oticon':
      return true
    default:
      return false
  }
}

export default function Brand() {
  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'))
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  let { brandname } = useParams(false)

  useEffect(() => {
    const setBrandName = async (key) => {
      const valid = validatePath(key)

      if (valid) {
        setNotFound(false)
        setLoading(false)
      } else {
        setNotFound(true)
        setLoading(false)
      }
    }

    setBrandName(brandname)

    return () => {
      setBrandName()
    }
  }, [brandname])

  if (loading) {
    return (
      <AppContainer>
        <Loading justify='center' />
      </AppContainer>
    )
  }

  if (notFound) {
    return <NotFound />
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <AppContainer>
        <Stack
          spacing={isLarge ? 8 : 14}
          sx={{
            paddingTop: isMedium
              ? theme.spacing(2)
              : isLarge
              ? theme.spacing(5)
              : theme.spacing(10),
            paddingBottom: theme.spacing(8)
          }}
        >
          <Form brandname={brandname} />
        </Stack>
      </AppContainer>
    </Box>
  )
}
