import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import { firestore } from '../lib/firebase'

import { addDoc, collection, serverTimestamp } from 'firebase/firestore'

import { getFirstPosition } from '../lib/functions'

export default function Pageview() {
  const { pathname } = useLocation()

  const handlePageview = async (collectionName, data) => {
    await addDoc(collection(firestore, collectionName), {
      ...data,
      created: serverTimestamp()
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    const obj = {
      event: 'pageview',
      pathname: pathname,
      browserId: localStorage.getItem('browserId'),
      sessionId: sessionStorage.getItem('sessionId'),
      sourceId: sessionStorage.getItem('sourceId'),
      originalLocation: sessionStorage.getItem('originalLocation'),
      clientTimezone: sessionStorage.getItem('tz')
    }

    handlePageview('events', obj)

    const pageTitle = getFirstPosition(pathname)

    const tagManagerArgs = {
      dataLayer: {
        event: 'pageview',
        pagePath: pathname,
        pageTitle
      }
    }

    TagManager.dataLayer(tagManagerArgs)
  }, [pathname])
  return null
}
