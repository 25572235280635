import { useState, useEffect, useContext } from 'react'
import useTheme from '@mui/material/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { AppContainer } from '../Components'

import oticonLogo from '../Assets/PNG/oticon-logo.png'
import phonakLogo from '../Assets/PNG/phonak-logo.png'
import yesLogo from '../Assets/PNG/yes-hearing-logo.png'

import { ContentContext } from '../lib/context'

export default function Header() {
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'))
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const [phoneNumber, setPhoneNumber] = useState(false)
  const [phoneNumberLink, setPhoneNumberLink] = useState()
  const { content } = useContext(ContentContext)
  const [brandLogo, setBrandLogo] = useState()

  useEffect(() => {
    if (content.removeYesLogo) {
      switch (content.brand) {
        case 'oticon':
          setBrandLogo(oticonLogo)
          break
        case 'phonak':
          setBrandLogo(phonakLogo)
          break
        default:
          setBrandLogo(yesLogo)
          break
      }
    } else {
      setBrandLogo(yesLogo)
    }
  }, [content.removeYesLogo, content.brand])

  useEffect(() => {
    setPhoneNumber(content.sourceData.phone)
  }, [content])

  useEffect(() => {
    setPhoneNumberLink(phoneNumber && 'tel:1' + phoneNumber.replaceAll('-', ''))
  }, [phoneNumber])

  if (!phoneNumberLink) {
    return null
  }

  return (
    <AppBar position='static' sx={{ backgroundColor: '#F6F9FF' }}>
      <AppContainer>
        <Toolbar
          sx={
            isSmall
              ? {
                  padding: `${theme.spacing(1)} 0 !important`
                }
              : {
                  padding: `${theme.spacing(2)} 0 !important`
                }
          }
        >
          <Stack
            direction={isSmall ? 'column' : 'row'}
            justifyContent={'space-between'}
            alignItems='center'
            sx={{ width: '100%' }}
          >
            <img
              alt='Brand Logo'
              src={brandLogo}
              style={
                isSmall
                  ? {
                      height: '50px',
                      paddingBottom: '20px',
                      paddingTop: '15px'
                    }
                  : { height: '50px' }
              }
            />
            <a href={phoneNumberLink}>
              <Typography
                variant='h4'
                color='secondary'
                sx={{
                  fontSize: isLarge
                    ? isMedium
                      ? '1.5rem'
                      : '2.25rem'
                    : '1.75rem'
                }}
              >
                {`Call Us: 1-${phoneNumber}`}
              </Typography>
            </a>
          </Stack>
        </Toolbar>
      </AppContainer>
    </AppBar>
  )
}
