import { useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import useTheme from '@mui/material/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'

import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { AppContainer } from './index'

import yesLogo from '../Assets/PNG/yes-hearing-logo-white.png'

import { getFirstPosition } from '../lib/functions'

import { ContentContext } from '../lib/context'

export default function Footer() {
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'))
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const history = useHistory()
  const { pathname } = useLocation()
  const page = getFirstPosition(pathname)
  const links =
    page === 'contact'
      ? ['Terms', 'Privacy']
      : ['Terms', 'Privacy', 'Contact', 'Do Not Sell my Personal Information']
  const { content } = useContext(ContentContext)

  const handleClick = (e) => {
    const target = e.currentTarget.value

    switch (target) {
      case 'terms':
      case 'privacy':
      case 'careers':
        history.push(`/${target}`)
        return
      case 'contact':
        history.push({ pathname: `/${target}`, state: { optOut: false } })
        return
      case 'do not sell my personal information':
        history.push({ pathname: '/contact', state: { optOut: true } })
        return
      default:
        return
    }
  }

  return (
    <Box sx={{ minHeight: 250, backgroundColor: theme.palette.secondary.main }}>
      <AppContainer>
        <Grid
          container
          spacing={5}
          direction='column'
          sx={{ padding: `${theme.spacing(4)} 0` }}
        >
          <Grid item xs={12} lg={6}>
            <Stack direction='row' alignItems='center' justifyContent='center'>
              <img
                alt='Yes Hearing Logo'
                src={yesLogo}
                style={{
                  height: '50px',
                  display: content.removeYesLogo ? 'none' : 'block'
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Stack
              spacing={2}
              sx={
                isLarge
                  ? isSmall
                    ? {}
                    : { padding: `0 ${theme.spacing(10)}` }
                  : {}
              }
            >
              <Box>
                <Typography
                  variant='subtitle2'
                  color='white'
                  // align={isLarge ? 'center' : 'left'}
                  align='center'
                  sx={{ fontWeight: 400 }}
                >
                  Our business hours are weekdays 8 am to 6 pm est and Saturdays
                  10 am to 5 pm est.
                </Typography>
              </Box>
              <Stack
                direction={isLarge ? 'column' : 'row'}
                spacing={2}
                justifyContent='center'
              >
                {links.map((option, i) => (
                  <Box
                    value={option.toLowerCase()}
                    onClick={handleClick}
                    component='button'
                    key={i}
                  >
                    <Typography variant='subtitle2' color='white'>
                      {option}
                    </Typography>
                  </Box>
                ))}
              </Stack>

              <Box>
                <Typography variant='subtitle2' color='white' align='center'>
                  © 2021 Greenlight Marketing LLC., all rights reserved. 8004 NW
                  154th St #632, Miami Lakes, FL 33016
                </Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </AppContainer>
    </Box>
  )
}
