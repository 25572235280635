import useTheme from '@mui/material/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'

import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

export default function SectionGridOne({
  children,
  direction,
  minHeight,
  xs,
  md,
  lg,
  removeMediaPadding
}) {
  const theme = useTheme()
  const isXSmall = useMediaQuery(theme.breakpoints.up('xs'))
  const isMedium = useMediaQuery(theme.breakpoints.up('md'))
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))

  let isVertical = false

  if (
    (xs === 12 && isXSmall && !isMedium) ||
    (md === 12 && isMedium && !isLarge) ||
    (lg === 12 && isLarge)
  ) {
    isVertical = true
  }

  const setMediaPadding = (isVertical, direction, removeMediaPadding) => {
    if (isVertical) {
      return { paddingBottom: theme.spacing(4) }
    } else if (removeMediaPadding) {
      return
    } else if (direction === 'row-reverse') {
      return { paddingLeft: theme.spacing(isMedium && !isLarge ? 2 : 4) }
    } else if (direction === 'row') {
      return { paddingRight: theme.spacing(isMedium && !isLarge ? 2 : 4) }
    }
  }

  return (
    <Grid container direction={direction} sx={{ minHeight: minHeight }}>
      <Grid
        item
        xs={xs}
        md={md}
        lg={lg}
        sx={setMediaPadding(isVertical, direction, removeMediaPadding)}
      >
        <Box display='flex' justifyContent='center' height='100%'>
          {children.filter((child) => child.key === 'media')}
        </Box>
      </Grid>
      <Grid
        item
        xs={xs}
        md={md}
        lg={lg}
        sx={
          isVertical
            ? { paddingBottom: 0 }
            : isLarge
            ? {
                paddingRight:
                  direction === 'row-reverse' ? theme.spacing(8) : 0,
                paddingLeft: direction === 'row' ? theme.spacing(8) : 0
              }
            : {}
        }
      >
        <Stack spacing={3} sx={{ height: '100%' }}>
          {children.filter((child) => child.key === 'header')}
          {children.filter((child) => child.key === 'body')}
          {children.filter((child) => child.key === 'button')}
        </Stack>
      </Grid>
    </Grid>
  )
}
