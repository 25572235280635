import { initializeApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'

const config = {
  apiKey: 'AIzaSyAb5LyGQjnXx35YhkWKbif09XvCbmGyNas',
  authDomain: 'carcharodon.firebaseapp.com',
  projectId: 'carcharodon',
  storageBucket: 'carcharodon.appspot.com',
  messagingSenderId: '25152517670',
  appId: '1:25152517670:web:887165f62e7dbb6e7418e7'
}

const firebase = initializeApp(config)

const firestore = getFirestore(firebase)

if (window.location.hostname === 'localhost') {
  connectFirestoreEmulator(firestore, 'localhost', 8080)
}

export { firebase, firestore }
