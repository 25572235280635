// import { Switch, Route, Redirect } from 'react-router-dom'
import { Switch, Route } from 'react-router-dom'

// import {
//   Brand,
//   Contact,
//   GenericNotFound,
//   Home,
//   Privacy,
//   Terms,
//   Theme
// } from '../Pages'

import { Brand, Contact, GenericNotFound, Privacy, Terms } from '../Pages'

export default function Routes() {
  return (
    <Switch>
      {/* <Route exact path='/' component={Home} /> */}
      {/* <Redirect exact from='/brand' to='/brand/phonak/' /> */}
      <Route strict path='/brand/:brandname' component={Brand} />
      <Route exact path='/contact' component={Contact} />
      <Route exact path='/privacy' component={Privacy} />
      <Route exact path='/terms' component={Terms} />
      {/* <Route exact path='/theme' component={Theme} /> */}
      <Route component={GenericNotFound} />
    </Switch>
  )
}
