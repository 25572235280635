import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { ContentProvider } from './lib/context'

import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

const theme = createTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 760,
      lg: 1100,
      xl: 1400
    },
    unit: 'px'
  },
  palette: {
    primary: {
      main: '#E48F34',
      light: '#FDC283',
      lighter: '#FBEDE0',
      dark: '#D97E1C',
      contrastText: '#030B26'
    },
    callButton: {
      main: '#00b67a',
      dark: '#1b5e20',
      contrastText: '#fff'
    },
    callButtonRed: {
      main: '#d23b28',
      dark: '#8e1910',
      contrastText: '#fff'
    },
    button: {
      main: '#FDC283',
      dark: '#E48F34',
      contrastText: '#030B26'
    },
    secondary: {
      main: '#0E2864',
      light: '#235cb3',
      dark: '#0A1D47',
      contrastText: '#FFFFFF'
    },
    background: {
      paper: '#fff',
      default: '#fff'
    },
    common: {
      black: '#171A21',
      white: '#FFFFFF',
      lightblue: 'rgb(206,230,250)',
      lightblue65: 'rgb(206,230,250,0.65)'
    },
    grey: {
      50: '#CDD6E0',
      100: '#C0CCD8',
      200: '#B4C2D0',
      300: '#A7B8C8',
      400: '#9BADCO',
      500: '#8EA3B8',
      600: '#7A93AC',
      700: '#758FA9',
      800: '#6885A1',
      900: '#5E7A97'
    },
    text: {
      primary: 'rgba(3,11,38,1)',
      secondary: 'rgba(3,11,38,0.6)',
      disabled: 'rgba(3,11,38,0.38)'
    }
  },
  typography: {
    fontWeightSemiBold: 600,
    h1: {
      fontFamily: 'Lora, serif'
    },
    h2: {
      fontFamily: 'Lora, serif'
    },
    h3: {
      fontFamily: 'Lora, serif',
      fontWeight: 700
    },
    h4: {
      fontFamily: 'Lora, serif',
      fontSize: '2rem',
      fontWeight: 700
    },
    h5: {
      fontFamily: 'Lora, serif',
      fontSize: '1.625rem',
      fontWeight: 500
    },
    h6: {
      fontFamily: 'Lora, serif',
      fontWeight: 600
    },
    body1: {
      fontSize: '1.25rem',
      fontWeight: 400,
      lineHeight: '1.25'
    },
    body2: {
      fontSize: '1.125rem'
    },
    subtitle1: { fontWeight: 500 },
    button: {
      textTransform: 'none',
      fontSize: '1.5rem'
    }
  }
})

if (window.location.hostname !== 'localhost') {
  LogRocket.init('vy8gna/the-hearing-agency')
  setupLogRocketReact(LogRocket)
}

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ContentProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ContentProvider>
  </ThemeProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
