import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

export default function Loading({ size, justify, align, pt, pl, pr }) {
  return (
    <Box
      display='flex'
      height='100%'
      justifyContent={justify}
      alignItems={align}
      pt={pt}
      pl={pl}
      pr={pr}
    >
      <CircularProgress size={size ? size : 60} />
    </Box>
  )
}
