import { useHistory } from 'react-router-dom'
import useTheme from '@mui/material/styles/useTheme'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { AppContainer, Loading } from '../Components'

export default function GenericNotFound() {
  const theme = useTheme()
  let history = useHistory()

  setTimeout(() => {
    history.push({ pathname: `/brand/phonak` })
  }, 5000)

  return (
    <AppContainer>
      <Stack alignItems='center'>
        <Box mb={theme.spacing(8)}>
          <Loading />
        </Box>
        <Typography variant='h4' align='center' color='secondary'>
          404: Not Found
        </Typography>
        <Typography
          variant='body1'
          align='center'
          color='secondary'
          sx={{ fontSize: '1.25rem' }}
        >
          You are being redirected.
        </Typography>
      </Stack>
    </AppContainer>
  )
}
