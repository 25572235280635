import * as yup from 'yup'

const validationSchema = yup.object({
  name: yup.string('Enter your name').max(75).required('Name is required'),
  phone: yup
    .string()
    .test('len', 'Invalid phone number', (val) => {
      let regex = /^\([0-9]+\) [0-9]+-[0-9]+$/i
      return regex.test(val)
    })
    .length(14, 'Invalid phone number')
    .required('Phone number is required'),
  email: yup.string().email('Email must be valid').max(50)
})

export default validationSchema
