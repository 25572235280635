import useTheme from '@mui/material/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'

import Container from '@mui/material/Container'

export default function AppContainer({ children }) {
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <Container
      maxWidth='xl'
      disableGutters
      sx={
        isLarge
          ? {
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2)
            }
          : {
              paddingLeft: theme.spacing(8),
              paddingRight: theme.spacing(8)
            }
      }
    >
      {children}
    </Container>
  )
}
