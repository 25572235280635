import { useState, useEffect, createContext } from 'react'
import queryString from 'query-string'

import { firestore } from '../lib/firebase'
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs
} from 'firebase/firestore'

export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
  const [content, setContent] = useState(null)

  const [loadingContent, setLoadingContent] = useState(true)

  const getLocation = async (lid) => {
    const docRef = doc(firestore, 'locations', lid)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      const data = docSnap.data()
      const name = data.name
      const lat = data.lat
      const lng = data.lng
      return { name, lat, lng }
    } else {
      return { name: false, lat: false, lng: false }
    }
  }

  const getSourceData = async (hostname, source) => {
    const getSources = async (docName) => {
      const q = query(
        collection(firestore, 'sources'),
        where('name', '==', docName)
      )

      const querySnapshot = await getDocs(q)

      return querySnapshot
    }

    const parseQuerySnapshot = (querySnapshot) => {
      let sourceDoc
      querySnapshot.forEach((doc) => {
        const sourceId = doc.id
        const data = doc.data()
        const phone = data.phone
        return (sourceDoc = { phone, sourceId })
      })
      return sourceDoc
    }

    const getSingleSource = async (docId) => {
      const document = await getDoc(doc(firestore, 'sources', docId))
      const sourceId = document.id
      const data = document.data()
      const phone = data.phone
      return { phone, sourceId }
    }

    let sourceDoc = false

    if (hostname === 'mail.yeshearing.com') {
      sourceDoc = await getSingleSource('koIXfuLUiT2hGdQGtqwp')
    } else {
      let querySnapshot = await getSources(source)

      if (querySnapshot.empty) {
        querySnapshot = await getSources(hostname)
        sourceDoc = parseQuerySnapshot(querySnapshot)
      } else {
        sourceDoc = parseQuerySnapshot(querySnapshot)
      }
    }

    if (!sourceDoc) {
      sourceDoc = await getSingleSource('wt9EtwHNTPfMxc7iWO8z')
    }

    sessionStorage.setItem('sourceId', sourceDoc.sourceId)

    return sourceDoc
  }

  useEffect(() => {
    ;(async () => {
      const hostname = window.location.hostname

      const ha = hostname === 'hearingagency.com'
      const queryParams = queryString.parse(window.location.search)

      const lid = queryParams.location || false

      if (lid) {
        const location = await getLocation(lid)
        sessionStorage.setItem('location', location.name)
        sessionStorage.setItem('lat', location.lat)
        sessionStorage.setItem('lng', location.lng)
      } else {
        sessionStorage.setItem('location', false)
      }

      const afsrc = queryParams.afsrc

      let source = afsrc || false
      const pathname = window.location.pathname
      const firstPath = pathname.split('/')[1]

      const brand = pathname.split('/')[2]

      if ('afsrc' in queryParams && firstPath === 'brand') {
        source = afsrc + '_' + brand
      }

      const sourceData = await getSourceData(hostname, source)

      const removeYesLogo = afsrc === 'ca' ? true : false

      setContent({ queryParams, sourceData, ha, removeYesLogo, brand })
      setLoadingContent(false)

      //Start inject NI scirpt
      if (afsrc === 'nint') {
        const script = document.createElement('script')
        script.innerHTML = `(function(i,n,v,o,c,a) { i.InvocaTagId = o; var s = n.createElement('script'); s.type = 'text/javascript';
          s.async = true; s.src = ('https:' === n.location.protocol ? 'https://' : 'http://' ) + v;
          var fs = n.getElementsByTagName('script')[0]; fs.parentNode.insertBefore(s, fs);
          })(window, document, 'solutions.invocacdn.com/js/invoca-latest.min.js', '1666/4160343089');`
        document.body.appendChild(script)
      }
      //End inject NI scirpt
    })()
  }, [])

  return (
    <ContentContext.Provider value={{ content, loadingContent }}>
      {children}
    </ContentContext.Provider>
  )
}
