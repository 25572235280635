import { useEffect, useState } from 'react'
import useTheme from '@mui/material/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import { SectionGridOne, TrustBox } from '../../../Components'

import Contact from './Contact'

import placeholder from '../../../Assets/PNG/removebg-phonak-paradise.png'

import phonak from '../../../Assets/PNG/removebg-phonak-paradise.png'
import resound from '../../../Assets/PNG/removebg-resound-one.png'
import starkey from '../../../Assets/PNG/removebg-starkey-livio.png'
import oticon from '../../../Assets/PNG/removebg-oticon-more.png'
import signia from '../../../Assets/PNG/removebg-signia-styletto.png'

import brands from '../brands.json'

export default function Form({ brandname }) {
  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'))

  const [content, setContent] = useState(false)
  const [png, setPNG] = useState(placeholder)

  useEffect(() => {
    const brand = brands.filter((brand) => brand.name === brandname)[0]
    setContent(brand)

    switch (brandname) {
      case 'phonak':
        setPNG(phonak)
        break
      case 'resound':
        setPNG(resound)
        break
      case 'starkey':
        setPNG(starkey)
        break
      case 'oticon':
        setPNG(oticon)
        break
      case 'signia':
        setPNG(signia)
        break
      default:
        setPNG(placeholder)
        break
    }
  }, [brandname])

  return (
    <SectionGridOne
      direction={isLarge ? 'column-reverse' : 'row-reverse'}
      minHeight={450}
      xs={12}
      md={12}
      lg={6}
    >
      <img
        key='header'
        src={png}
        alt={`${brandname} hearing device`}
        style={{
          height: isLarge ? 366 : 438,
          width: isLarge ? 350 : 419,
          marginBottom: isMedium ? '-4.5rem' : 0,
          transform: isMedium
            ? 'scale(0.65)'
            : isLarge
            ? 'scale(0.75)'
            : 'scale(1)'
        }}
      />

      {/* <Typography key='header' variant='h4' color='secondary'>
        Get your exclusive price quote and save 25-40% off
      </Typography> */}
      {/* <Stack key='body' spacing={3}>
        <Typography variant='body1'>
          Fill out this form and our licensed hearing expert will call you
          within minutes.
        </Typography>
        <Typography variant='body1'>
          We'll give you an all-inclusive price quote. No hidden fees. No sales
          pitch.
        </Typography>
        <Typography variant='body1'>
          In five minutes or less, you can save 25-40% off your next hearing
          aids.
        </Typography>
        <Typography variant='body1'>
          Offered in partnership with Yes Hearing. Our business hours are
          weekdays 8 am to 6 pm and Saturdays 10 am to 5 pm est.
        </Typography>
      </Stack> */}

      <Stack key='media'>
        <Box>
          <Box sx={{ marginBottom: theme.spacing(4) }}>
            <Typography variant='h4' color='secondary' id='pricing'>
              {`Save 25-40% off ${content.productName} hearing aids`}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: theme.spacing(3) }}>
            <Typography variant='body1'>{content.body}</Typography>
          </Box>
        </Box>
        <Box
          sx={
            isLarge
              ? { marginBottom: theme.spacing(-2) }
              : { marginBottom: theme.spacing(3) }
          }
        >
          <TrustBox />
        </Box>
        <Contact />
      </Stack>
    </SectionGridOne>
  )
}
