import TagManager from 'react-gtm-module'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'

const timezone = require('dayjs/plugin/timezone')
dayjs.extend(timezone)

export const setTrackingIds = async () => {
  if (!localStorage.getItem('browserId')) {
    const newBrowserId = uuidv4()
    localStorage.setItem('browserId', newBrowserId)
  }
  const timezone = dayjs.tz.guess()
  const originalLocation =
    document.location.protocol +
    '//' +
    document.location.hostname +
    document.location.pathname +
    document.location.search

  sessionStorage.setItem('sessionId', uuidv4())
  sessionStorage.setItem('originalLocation', originalLocation)
  sessionStorage.setItem('tz', timezone)

  const tagManagerArgs = {
    gtmId: 'GTM-P4VQSCC',
    dataLayer: {
      originalLocation: originalLocation
    }
  }

  TagManager.initialize(tagManagerArgs)
}

export const getFirstPosition = (pathname) => {
  pathname.indexOf(1)
  pathname.toLowerCase()
  pathname = pathname.split('/')[1]
  return pathname
}
