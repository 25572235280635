import { forwardRef, useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { IMaskInput } from 'react-imask'
import useTheme from '@mui/material/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Paper from '@mui/material/Paper'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormHelperText from '@mui/material/FormHelperText'

import { AppContainer, Loading } from '../Components'

import { firestore } from '../lib/firebase'

import { addDoc, collection, serverTimestamp } from 'firebase/firestore'

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props

  return (
    <IMaskInput
      {...other}
      mask='(#00) 000-0000'
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.id, value } })}
      overwrite
    />
  )
})

const contactSchema = [
  {
    label: 'Name',
    id: 'name',
    placeholder: 'Enter your full name',
    multiline: false,
    type: 'text'
  },
  {
    label: 'Phone Number',
    id: 'phone',
    placeholder: 'Enter your phone number',
    multiline: false,
    type: 'tel'
  },
  {
    label: 'Email Address',
    id: 'email',
    placeholder: 'Enter your email address',
    multiline: false,
    type: 'email'
  },
  {
    label: 'Message',
    id: 'message',
    placeholder: 'Enter your message',
    multiline: true,
    type: 'textarea',
    inputType: 'text'
  }
]

const optOutSchema = [
  {
    label: 'Name',
    id: 'name',
    placeholder: 'Enter your full name',
    multiline: false,
    type: 'text'
  },
  {
    label: 'Phone Number',
    id: 'phone',
    placeholder: 'Enter your phone number',
    multiline: false,
    type: 'tel'
  },
  {
    label: 'Email Address',
    id: 'email',
    placeholder: 'Enter your email address',
    multiline: false,
    type: 'email'
  },
  {
    label: 'Mailing Address',
    id: 'address',
    placeholder: 'Enter your full mailing address',
    multiline: false,
    type: 'text'
  },
  {
    label: 'Message',
    id: 'message',
    placeholder: 'Enter your message',
    multiline: true,
    type: 'textarea',
    inputType: 'text',
    disabled: true
  }
]

export default function Contact() {
  const theme = useTheme()

  const [inputs, setInputs] = useState(false)
  const [validation, setValidation] = useState(false)
  const [loading, setLoading] = useState(false)
  const [collectionName, setCollectionName] = useState(false)
  const [success, setSuccess] = useState(false)
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  const location = useLocation()

  useEffect(() => {
    if (location.state.optOut) {
      setInputs(optOutSchema)
      setCollectionName('contact.optouts')
      setValidation(
        yup.object({
          name: yup
            .string('Enter your name')
            .max(75, 'Name must be at most 75 characters')
            .required('Name is required'),
          phone: yup
            .string()
            .test('len', 'Invalid phone number', (val) => {
              let regex = /^\([0-9]+\) [0-9]+-[0-9]+$/i
              return regex.test(val)
            })
            .length(14, 'Invalid phone number')
            .required('Phone number is required'),
          email: yup
            .string()
            .email('Email must be valid')
            .max(50, 'Email must be at most 50 characters')
            .required('Email is required'),
          address: yup
            .string()
            .max(50, 'Address must be at most 50 characters')
            .required('Address is required'),
          message: yup
            .string()
            .max(275, 'Message must be at most 275 characters')
            .required('Message is required')
        })
      )
    } else {
      setInputs(contactSchema)
      setCollectionName('contact.forms')
      setValidation(
        yup.object({
          name: yup
            .string('Enter your name')
            .max(75, 'Name must be at most 75 characters')
            .required('Name is required'),
          phone: yup
            .string()
            .test('len', 'Invalid phone number', (val) => {
              let regex = /^\([0-9]+\) [0-9]+-[0-9]+$/i
              return regex.test(val)
            })
            .length(14, 'Invalid phone number')
            .required('Phone number is required'),
          email: yup
            .string()
            .email('Email must be valid')
            .max(50, 'Email must be at most 50 characters')
            .required('Email is required'),
          message: yup
            .string()
            .max(275, 'Message must be at most 275 characters')
            .required('Message is required')
        })
      )
    }
  }, [location])

  const formik = useFormik({
    initialValues: location.state.optOut
      ? {
          name: '',
          phone: '',
          email: '',
          address: '',
          message: `If you're submitting a request related to one of your opt-out rights under the California Consumer Privacy Act, please include your name, email address, phone number, and full mailing address so that we may process it correctly.`
        }
      : {
          name: '',
          phone: '',
          email: '',
          message: ''
        },
    validationSchema: validation,
    onSubmit: async (values) => {
      setLoading(true)

      try {
        const docRef = await addDoc(collection(firestore, collectionName), {
          ...values,
          browserId: localStorage.getItem('browserId'),
          sessionId: sessionStorage.getItem('sessionId'),
          tenantId: 'wRzt80KPc06nd2lX9upo',
          created: serverTimestamp()
        })
        if (docRef.id) {
          setTimeout(() => {
            setSuccess(true)
          }, 500)
        }
      } catch (error) {
        console.error(error)
        setTimeout(() => {
          setLoading(false)
          setTimeout(() => {
            alert('Please wait a few minutes and try again.')
          }, 50)
        }, 500)
      }
    }
  })

  if (success) {
    return (
      <Stack
        spacing={theme.spacing(2)}
        alignItems='center'
        sx={{ paddingTop: theme.spacing(15), paddingBottom: theme.spacing(20) }}
      >
        <Typography variant='h4' color='secondary'>
          Thank You!
        </Typography>
        <Typography variant='body2' align='center' sx={{ maxWidth: '60%' }}>
          {location.state.optOut
            ? `Your infromation has been received.`
            : `We appreciate you contacting The Hearing Agency. One of our colleagues will get back in touch with you soon! Have a great day!`}
        </Typography>
      </Stack>
    )
  }

  return (
    <AppContainer>
      <form onSubmit={formik.handleSubmit}>
        <Paper
          elevation={4}
          sx={{
            padding: theme.spacing(4),
            margin: isMedium
              ? `${theme.spacing(10)} 0 ${theme.spacing(15)} 0`
              : `${theme.spacing(10)} ${theme.spacing(5)} ${theme.spacing(
                  15
                )} ${theme.spacing(5)}`
          }}
        >
          <Stack spacing={4}>
            <Typography variant='h4' color='secondary' gutterBottom>
              Contact Form
            </Typography>
            {inputs &&
              inputs.map((item) => (
                <FormControl key={item.id}>
                  <InputLabel
                    htmlFor={item.id}
                    variant='standard'
                    error={
                      formik.touched[item.id] && Boolean(formik.errors[item.id])
                    }
                    sx={{
                      top: item.id === 'message' ? '-20%' : '-35%',
                      fontSize: '1.5rem',
                      color: theme.palette.secondary.main
                    }}
                    shrink
                  >
                    {item.label}
                  </InputLabel>
                  <OutlinedInput
                    id={item.id}
                    type={item.type}
                    placeholder={item.placeholder}
                    label={item.label}
                    value={formik.values[item.id]}
                    onChange={formik.handleChange}
                    error={
                      formik.touched[item.id] && Boolean(formik.errors[item.id])
                    }
                    inputComponent={
                      item.id === 'phone'
                        ? TextMaskCustom
                        : item.id === 'message'
                        ? 'textarea'
                        : 'input'
                    }
                    notched={false}
                    multiline={item.multiline}
                    rows={item.multiline ? 4 : 1}
                    fullWidth
                    sx={{
                      backgroundColor: theme.palette.common.white
                    }}
                    disabled={item.disabled}
                  />
                  <FormHelperText
                    error={
                      formik.touched[item.id] && Boolean(formik.errors[item.id])
                    }
                    sx={{ minHeight: '1.25rem' }}
                  >
                    {formik.touched[item.id] && formik.errors[item.id]}
                  </FormHelperText>
                </FormControl>
              ))}
            <Box
              width='100%'
              display='flex'
              justifyContent={isMedium ? 'center' : 'flex-end'}
            >
              {loading ? (
                <Loading pr={isMedium ? 0 : 14.5} size={54} />
              ) : (
                <Button
                  variant='contained'
                  color='button'
                  disableElevation
                  component='button'
                  type='submit'
                  sx={{
                    width: '17.375rem',
                    borderRadius: 4
                  }}
                >
                  Submit
                </Button>
              )}
            </Box>
          </Stack>
        </Paper>
      </form>
    </AppContainer>
  )
}
